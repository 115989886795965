//Theme Vars
$theme-name: gold-coast-private-hospital;

.theme-#{$theme-name} {
  .top-bar {
    ul.nav {
      .help-line {
        display: none;
      }
    }
  }
}
