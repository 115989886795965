.register-scroll {
  height: 100vh;
  overflow: auto;
}

.register-header {
  margin-top: 60px;
  margin-bottom: 260px;
  width: 100%;
  height: 400px;
  background-image: url(../../assets/images/gold-coast-hero.jpg);
  background-size: cover;
  background-position: center top;
  background-color: $color-white;
  display: flex;
  justify-content: center;
  text-align: center;

  &.company-signup {
    background-image: url(../../assets/images/gold-coast-ph.jpg);
  }

  .text-wrap {
    margin: 80px 0 40px;
  }

  h3 {
    margin-top: 30px;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    color: $color-doc-brand-sec;
    font-size: 26px;
  }

  h1 {
    margin: 0;
    color: $color-white;
    font-weight: 300;
    font-size: 2.5em;
    line-height: 1.2;
  }

  .app-store-top {
    text-align: center;
    margin-bottom: 20px;

    a {
      display: inline-block;

      img {
        height: 50px;
      }
    }
  }
}

.register-block-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: -100px;

  a.link {
    color: $color-doc-brand-sec;

    &:hover {
      color: darken($color-doc-brand-sec, 10%);
    }
  }

  .rb-item {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 380px;
    height: auto;
    margin: 0 30px;
    padding: 35px 42px;
    background: #fff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .rb-header {
      min-height: 30px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      h4 {
        margin: 0;
        font-size: 1px;
        text-align: center;
        font-weight: 400;
        color: #555555;
      }

      p {
        font-size: 1.2em;
        font-weight: 400;
        color: #3b3b3b;
        margin: 0;
      }

      .muted {
        font-size: 1em;
        color: #88888b;
      }
    }

    .rb-body {
      padding: 20px 0 0;

      input,
      select,
      textarea {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        line-height: 40px;
        padding: 0 10px;
        font-size: 0.875em;
        border-color: #cccccc;
        margin: 0 0 8px 0;
      }

      textarea {
        height: 60px;
        padding: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        resize: none;
      }
    }

    .error-alert {
      margin: -10px 0 10px;
      background: rgba(194, 83, 83, 0.3);
      border-radius: 4px;
      margin-bottom: 15px;
      padding: 10px;
      text-align: left;
      font-size: 0.75em;
      color: #c25353;
      line-height: 1.2;

      p {
        margin-bottom: 4px;
      }

      ul {
        list-style: disc;
        margin: 0 0 4px 14px;

        li {
        }
      }
    }

    .rb-footer {
      margin-top: auto;
      align-self: flex-end;
      width: 100%;

      .btn {
        width: 100%;
        margin: 8px 0;
        border: none;
        text-transform: none;
      }

      .btn-info {
        &:hover,
        &:focus {
          background-color: darken($color-doc-brand-sec, 7.5%);
          color: #fff;
        }
      }

      .btn-red {
        &:hover,
        &:focus {
          background-color: darken($color-danger, 7.5%);
          color: #fff;
        }
      }
    }
  }

  .app-store-link {
    display: none;
    margin-bottom: 50px;
    text-align: center;

    a {
      display: inline-block;

      img {
        height: 60px;
      }
    }
  }

  /*Company Sign Up Form*/
  .cs-wrap {
    min-height: 290px;
    padding: 20px 40px;

    .rb-header {
      small {
        i {
          font-size: 16px;
          margin-left: 8px;
          vertical-align: middle;
        }
      }

      img {
        max-height: 60px;
        margin: 10px 0;
      }

      .prev {
        display: block;
        margin-bottom: 5px;
        font-size: 20px;
        color: $color-dark-grey;
        font-weight: 400;

        i {
          display: inline-block;
          vertical-align: middle;
          font-size: 13px;
          margin-bottom: 2px;
        }

        &:hover {
          color: darken ($color-dark-grey, 10%);
          text-decoration: none;
        }
      }
    }

    .rb-body {
      .btn {
        width: 100%;
        margin: 8px 0;
        border: none;
        text-transform: none;
      }

      .btn-info {
        &:hover {
          background-color: darken($color-doc-brand-sec, 7.5%);
          color: #fff;
        }
      }

      .btn-red {
        &:hover {
          background-color: darken($color-danger, 7.5%);
          color: #fff;
        }
      }

      .form-group {
        display: flex;
        justify-content: space-between;

        input {
          width: 48%;
        }
      }

      small {
        display: inline-block;
        margin: 8px 0;
      }

      label {
        font-weight: 300;
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        margin: 3px 0;

        input {
          margin: 0;
        }

        span,
        a {
          display: inline-block;
        }

        a {
          margin: 0 3px;
          color: $color-doc-brand-sec;
          text-decoration: underline;
        }

        input[type="radio"],
        input[type="checkbox"] {
          width: auto;
          display: inline-block;
          height: auto;
          margin-right: 2px;
        }
      }
    }

    .rb-message {
      margin-top: 30px;

      p {
        font-weight: 400;
        font-size: 14px;
        padding: 0 20px;
        line-height: 1.3;
      }
    }
  }
}

.theme-gold-coast-private-hospital {
  overflow: scroll !important;

  .register-header {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 1150px) {
}

@media only screen and (max-width: 1024px) {
  .register-header {
    margin-top: 0;
    height: auto;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 990px) {
}

@media only screen and (max-width: 768px) {
  .register-header {
    height: 100%;
    margin-bottom: 0;
  }

  .register-block-wrap {
    margin-bottom: 0;
    flex-direction: column;
    align-items: center;

    .rb-item {
      margin-bottom: 60px;
    }

    .app-store-link {
      display: block;
    }

    &.flex-reverse {
      flex-direction: column-reverse;
    }
  }
}

@media only screen and (max-width: 580px) {
}
